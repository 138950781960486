import React from "react";
import "../styles/Services.css";
import sfive from "../assets/sfive.jpg";
import sfour from "../assets/sfour.jpg";
import sthree from "../assets/sthree.jpg";

const Services = () => {
  return (
    <section className="containerServices">
      <h1 className="textServices">Our Services</h1>
      <div className="innerContainerServices">
        <div className="bodyContainerServices">
          <img
            src={sfive}
            alt="Solar Panel Installation"
            className="serviceImage"
          />
          <h1 className="headerTitleServices">Solar Panel Installation</h1>
          <p className="paragraphHomeServices">Solar Panel Installation</p>
        </div>
        <div className="bodyContainerServiceMiddle">
          <img
            src={sfour}
            alt="Solar Energy Consulting"
            className="serviceImage"
          />
          <h1 className="headerTitleServices">Solar Energy Consulting</h1>
          <p className="paragraphHomeServices">Solar Energy Consulting</p>
        </div>
        <div className="bodyContainerServices">
          <img
            src={sthree}
            alt="Maintenance and Support"
            className="serviceImage"
          />
          <h1 className="headerTitleServices">Maintenance and Support</h1>
          <p className="paragraphHomeServices">Maintenance and Support</p>
        </div>
      </div>
    </section>
  );
};

export default Services;
